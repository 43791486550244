.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Body */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Header */
header {
  background-color: #343a40;
  color: #fff;
  padding: 20px;
}

header h1 {
  margin: 5%;
  font-size: 2.5rem;
}

h3 {
  font-size: 1.5rem;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  text-align: center;
}

table th {
  background-color: #343a40;
  color: #fff;
}

/* Table hover effect */
table tbody tr:hover {
  background-color: #f2f2f2;
}

/* Buttons */
button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin: 3px;
}

a {
  text-decoration: none; /* Enlève le soulignement */
  color: inherit; /* Utilise la couleur par défaut du texte */
}

button:hover {
  background-color: #0056b3;
}

/* Forms */
input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Footer */
footer {
  background-color: #343a40;
  color: #fff;
  text-align: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  bottom: 0;
}
