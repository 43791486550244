/* ModifyReservation.css */
.reservation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.reservation-form label {
    margin-bottom: 10px;
}

.reservation-form input,
.reservation-form button {
    width: 200px;
    padding: 10px;
    margin-top: 5px;
}

.reservation-form button {
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reservation-form button:hover {
    background-color: #007B9A;
}

.reservation-form p {
    color: green;
    margin-top: 20px;
}
